<template>
    <div class="bar-wrapper">
            <div class="player-time" >
                <p style="font-weight: 900">{{ (store.tunecast.streaming_goal - streams).toLocaleString() }}</p>
                <p style="font-size: 10px;">TO GOAL</p>
            </div>
            <div class="player-bar-wrapper">
                <div class="player-bar-progress" :style="`height: .6rem; width: ${(Math.min(streams / store.tunecast.streaming_goal, 1)) * 100}%`"></div>
                <div class="player-bar" style="height: .6rem"></div>
            </div>
            <div class="player-time" style="text-align: center">
                <p style="font-weight: 900">{{(+store.tunecast.streaming_goal).toLocaleString()}}</p>
                <p style="font-size: 10px;">STREAMS</p>
            </div>
    </div>
    </template>
    <style scoped>
        .footer {
            position: absolute;
            bottom: 1rem;
            right: .5rem;
        }
    
        .footer li {
            display: inline;
            margin-right: .5rem;
            font-size: .85rem;
        }
    
        .footer li a {
            color: #000;
            transition: .2s ease all;
        }
    
        .footer li a:hover {
            opacity: .8;
        }
    </style>
    <script setup>
    // import { storeToRefs } from 'pinia';
    // import { formatTime } from '@/utils';
    import { useStore } from '@/store';
    // import { usePlayerStore } from '@/store/player';
    const store = useStore();

    import { BACKEND_URL } from "@/services/config";
    import { onMounted, onUnmounted, ref } from 'vue';
    const streams = ref(0);

    async function loadStreams() {
        let data = await (await fetch(BACKEND_URL + '/streams?tunecast=' + store.tunecast.id)).json();
        streams.value = data.streams;
    }

    let _interval;
    onMounted(() => {
        _interval = setInterval(loadStreams, 20000);
        loadStreams();
    })

    onUnmounted(() => {
        clearInterval(_interval);
    })



    </script>
    
    <style type="text/css" scoped>
    .bar-wrapper {
		height: 1rem;
		width: 100%;
		display: flex; 
		flex-direction: row; 
		/* flex: 1;  */
        margin-top: -.8rem;
		align-items: center; 
		position: relative;
        height: 3rem;
		transition: all .2s ease;
        color: #fff;
	}

    .player {
        position: fixed;
        bottom: 0;
        height: 4.8rem;
        width: 100vw;
        background-color: #fff;
        z-index: 3500;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .now-playing-meta {
        padding: .5rem 1.5rem;
        margin-right: 3rem;
    }
    
    .now-playing-meta p {
        color: #C5C5C5;
        font-weight: 600;
        font-size: 8px;
        margin-bottom: .2rem;
    }
    
    .now-playing-meta .current-track {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    
    .now-playing-meta .current-track img {
        height: 2.8rem;
    }
    .now-playing-meta .current-track-meta {
        color: #000;
        margin-left: 1rem;
    }
    .now-playing-meta .current-track-meta h3:nth-child(2) {
        margin-top: -.2rem;
        font-weight: 200;
    }
    
    .player-time {
        font-weight: 800;
        color: #fff;
        margin: 2rem;
    }

    @media screen and (max-width: 700px) {
        .player-time {
            margin: 2rem 1rem;
        }
    }
    
    .player-bar-wrapper {
        flex: 1;
        position: relative;
        display: flex;
        align-items: center;
    }
    
    .player-bar-progress {
        position: absolute;
        height: .6rem;
        border-radius: 10px;
        background-color: #fff;
        transition: all .1s ease;
    }
    
    .player-bar-ball {
        transition: all .1s ease;
        position: absolute;
        height: .75rem;
        width: .75rem;
        border-radius: 50%;
        background-color: #000;
    }
    
    .player-bar {
        position: relative;
        height: .6rem;
        width: 100%;
        border-radius: 10px;
        background-color: rgba(255,255,255,.2);
        box-shadow: inset 0 0 5px 2px rgba(255,255,255,.2);
    }
    
    
    @media screen and (max-width: 900px) {
        .player {
            display: none;
        }
    }
    
    </style>