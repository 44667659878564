<template>
<div class="player">
	<!-- player -->
	<div class="now-playing-meta" v-if="state === 2">
		<!-- now playing -->
		<p>{{LOCALE[store.tunecast.lang]['NOWPLAYING']}}</p>

		<div class="current-track">
			<img :src="currentTrack.artwork">

			<div class="current-track-meta">
				<h3 v-if="currentTrack && currentTrack.name">{{truncate(currentTrack.name)}}</h3>
				<h3>{{currentTrack.artist}}</h3>
			</div>
		</div>
	</div>

	<div v-if="state === 2" style="flex: 1; margin-right: 9rem;">
		<div class="bar-wrapper" :style="store.tunecast.streaming_goal ? (playerState === 2 ? 'bottom: 2.8rem' : 'bottom: -1.2rem;') : ''"
		>
			<div class="player-time">{{formatTime(currentTrack.progress)}}</div>
			<div class="player-bar-wrapper">
				<div class="player-bar-ball" :style="`left: ${(currentTrack.progress / currentTrack.duration) * 100 - 1}%`"></div>
				<div class="player-bar-progress" :style="`width: ${(currentTrack.progress / currentTrack.duration) * 100}%`"></div>
				<div class="player-bar"></div>
			</div>
			<div class="player-time">{{formatTime(currentTrack.duration)}}</div>
		</div>

		<div v-if="store.tunecast.streaming_goal" class="bar-wrapper" :style="playerState === 1 ? 'top: 2.8rem' : 'top: -.65rem'">
			<div class="player-time" style="font-weight: 900">0</div>
            <div class="player-bar-wrapper">
                <div class="player-bar-progress" :style="`height: .6rem; width: ${(Math.min(streams / store.tunecast.streaming_goal, 1)) * 100}%`"></div>
                <div class="player-bar" style="height: .6rem"></div>
            </div>
            <div class="player-time" style="text-align: center">
                <p style="font-weight: 900">{{(+store.tunecast.streaming_goal).toLocaleString()}}</p>
                <p style="font-size: 12px;">STREAMS</p>
            </div>
		</div>
	</div>
</div>
</template>
<style scoped>
	.footer {
		position: absolute;
		bottom: 1rem;
		right: .5rem;
	}

	.footer li {
		display: inline;
		margin-right: .5rem;
		font-size: .85rem;
	}

	.footer li a {
		color: #000;
		transition: .2s ease all;
	}

	.footer li a:hover {
		opacity: .8;
	}

	.bar-wrapper {
		height: 4.8rem;
		width: 100%;
		display: flex; 
		flex-direction: row; 
		/* flex: 1;  */
		align-items: center; 
		position: relative;
		margin: 0 7.5rem 0 2.4rem;
		height: 100%;
		transition: all .2s ease;
	}
</style>
<script setup>
import { storeToRefs } from 'pinia';
import { formatTime } from '@/utils';
import { useStore } from '@/store'
import { usePlayerStore } from '@/store/player';
import LOCALE from '@/services/locale';
import { BACKEND_URL } from "@/services/config";
import { ref, onMounted, onUnmounted } from 'vue';
const playerState = ref(1);
const streams = ref(0);

const truncate = str => str.length > 27 ? str.slice(0,26) + '...' : str;

const store = useStore();
const { state } = storeToRefs(store)
const playerStore = usePlayerStore();
const { currentTrack } = storeToRefs(playerStore);

async function loadStreams() {
	let data = await (await fetch(BACKEND_URL + '/streams?tunecast=' + store.tunecast.id)).json();
	streams.value = data.streams;
}


let _interval1, _interval2;
onMounted(() => {
	if(store.tunecast.streaming_goal) {
		_interval1 = setInterval(loadStreams, 20000);
		loadStreams();
		_interval2 = setInterval(() => {
			playerState.value = playerState.value === 1 ? 2 : 1;
		}, 5000);
	}
})

onUnmounted(() => {
	clearInterval(_interval1);
	clearInterval(_interval2)
})
</script>

<style type="text/css" scoped>

.player {
	position: fixed;
	bottom: 0;
	height: 4.8rem;
	width: 100vw;
	background-color: #fff;
	z-index: 3500;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.now-playing-meta {
	padding: .5rem 1.5rem;
	margin-right: 3rem;
}

.now-playing-meta p {
	color: #C5C5C5;
	font-weight: 600;
	font-size: 8px;
	margin-bottom: .2rem;
}

.now-playing-meta .current-track {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.now-playing-meta .current-track img {
	height: 2.8rem;
}
.now-playing-meta .current-track-meta {
	color: #000;
	margin-left: 1rem;
}
.now-playing-meta .current-track-meta h3:nth-child(2) {
	margin-top: -.2rem;
	font-weight: 200;
}

.player-time {
	font-weight: 800;
	color: #000;
	margin: 0 2rem;
}

.player-bar-wrapper {
	flex: 1;
	position: relative;
	display: flex;
	align-items: center;
}

.player-bar-progress {
	position: absolute;
	height: .3rem;
	border-radius: 10px;
	background-color: rgba(0,0,0,1);
	transition: all .1s ease;
}

.player-bar-ball {
	transition: all .1s ease;
	position: absolute;
	height: .75rem;
	width: .75rem;
	border-radius: 50%;
	background-color: #000;
}

.player-bar {
	position: relative;
	height: .2rem;
	width: 100%;
	border-radius: 10px;
	background-color: rgba(0,0,0,.2);
}


@media screen and (max-width: 900px) {
	.player {
		display: none;
	}
}

</style>